

<template>
  <div>
    <textarea class="w-full h-28 border-gray-300 rounded" v-model="content"></textarea>
    <button class="px-4 py-2 bg-blue-600 text-white mt-2 rounded" @click="generater" >生成试一下</button>
    <div class="bg-gray-50 px-4 py-8">
      {{msg}}
    </div>
  </div>
</template>

<style scoped>

</style>
<script setup>
import {ref} from "vue";
import axios from "axios";
const content = ref(null)
const post_msg = ref(null)
const msg = ref(null)
const generater = ()=>{
  post_msg.value = content.value
  axios.post('http://127.0.0.1:5000',{
    data:post_msg.value
  }).then((res)=>{
    // msg.value = res.data.kwargs.content
    msg.value = res.data.data
    console.log(res.data.data)
  })
}
</script>