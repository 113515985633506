

<template>
  <div class="bg-white m-4 rounded-xl flex flex-col">
    <div class="mt-2 ml-4 p-2 text-xl text-gray-600">
      分类管理
    </div>
    <div class="flex ml-4 p-2">
      <div class="flex items-center  text-gray-500  border px-4 py-1 rounded cursor-pointer hover:bg-gray-100" @click="add">
        <plus-icon class="ico font-bold"></plus-icon>
        <span>添加分类</span>
      </div>
      <div class="hidden flex items-center ml-4 text-gray-500  border px-4 py-1 rounded cursor-pointer hover:bg-red-100 hover:text-red-600">
       <trash-icon class="ico font-bold"></trash-icon>
        <span>删除</span>
      </div>
      <div class="hidden flex items-center ml-4 text-gray-500  border px-4 py-1 rounded cursor-pointer hover:bg-gray-100">
       <arrow-up-on-square-icon class="ico font-bold font-black"></arrow-up-on-square-icon>
        <span>导出</span>
      </div>
    </div>
    <div class="table w-full border-collapse border-gray-100 m-4">
      <table class="w-full">
        <thead>
        <tr class="bg-gray-100 rounded">
          <th class="px-6 py-3 text-left ">ID</th>
          <th class="px-6 py-3 text-left ">图片</th>
          <th class="px-6 py-3 text-left ">名称</th>
          <th class="px-6 py-3 text-left ">排序</th>
          <th class="px-6 py-3 text-left ">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr class="border-t border-gray-100 hover:bg-gray-50 cursor-pointer" v-for="(item,index) in cateList " :key="index">
          <td class="px-4 py-4 text-gray-500">{{ index + 1 }}</td>
          <td class="px-4 py-4 text-gray-500"><img :src="item.image" alt="Image 1" class="h-10 w-10 rounded-full"></td>
          <td class="px-4 py-4 text-gray-500">{{item.name}}</td>
          <td class="px-4 py-4 text-gray-500">{{item.sort}}</td>
          <td class="px-4 py-4 ml-auto">
            <button class="text-blue-300 hover:text-blue-400  rounded mr-2 bg-blue-50 py-2 px-4" @click="editItem(item)">编辑</button>
            <button class="text-red-300 bg-red-50 py-2 px-4 rounded hover:bg-red-100" @click="deleteItem(item.id)">删除</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" v-if="showForm" @click="hideForm">
    <div class="bg-white p-4 rounded shadow-lg w-4/12" @click.stop>
      <h2 class="text-xl mb-4">添加分类</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-4 flex">
          <label for="name" class="block w-12 mr-2 text-sm font-medium text-gray-700  items-start mb-2 py-2">名称:</label>
          <input v-model="formSubmitData.name" type="text" id="name" name="name" placeholder="请输入名称"
                 class="focus:outline-none  border w-10/12 bg-gray-50 px-2 rounded-md shadow-sm py-2">
        </div>
        <div class="mb-4 flex">
          <label for="image" class="block text-sm font-medium text-gray-700 py-2 w-12 items-start mr-2">图片:</label>
          <input v-model="formSubmitData.image" type="text" id="image" name="image" placeholder="请输入图片地址"
                 class="focus:outline-none  border w-7/12 bg-gray-50 px-2 rounded-md shadow-sm py-2">
          <button class="w-3/12 text-blue-300 hover:text-blue-400  rounded mr-2 bg-blue-50 py-2 px-3 ml-2" @click="uploadFile">上传</button>
          <input type="file" id="fileInput" ref="fileInput" style="display: none;" @change="handleFileChange">

        </div>
        <div class="mb-4 flex">
          <label for="sort" class="block text-sm font-medium text-gray-700 w-12 mr-2 items-start py-2">排序:</label>
          <input v-model="formSubmitData.sort" type="text" id="name" name="name" placeholder="请输入数字" class="border w-3/12 focus:outline-none  bg-gray-50 px-2 rounded-md shadow-sm py-2"></div>
        <div class="text-right">
          <button type="submit" class="px-6 py-2 mr-8 bg-gray-100 text-gray-500 rounded hover:bg-gray-200" @click="hideForm">取消</button>
          <button type="submit" class="px-6 py-2 bg-blue-400 text-white  hover:text-white rounded hover:bg-blue-500" @click="formSave" >提交</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>
<script setup>
import {PlusIcon,TrashIcon,ArrowUpOnSquareIcon} from "@heroicons/vue/20/solid";
import {ref} from "vue";
import axios from "axios";
const showForm = ref(false);
const formSubmitData = ref({
  name:'',
  image:'',
  sort:''
})
const cateList = ref();
const fileInput = ref()
function add(){
  showForm.value = true
}
function hideForm(){
  showForm.value = false
}
async function formSave(){
  try{
    await axios.post('api/category/save',formSubmitData.value);
    getCateList()
    formSubmitData.value = ref()
    showForm.value = false
  }catch (error){
    console.error('提交失败',error)
  }
}
function editItem(item){
  showForm.value = true;
  formSubmitData.value = JSON.parse(JSON.stringify(item));
}
function deleteItem(id){
  axios.post('api/category/delete',{
    id
  }).then((res)=>{
    getCateList()
    console.log(res)
  })
}
function getCateList(){
  axios.get('api/category/index').then((res)=>{

    cateList.value = res.data.data
  })
}
getCateList();
function uploadFile(){
  fileInput.value.click();
  fileInput.value.onchange = ()=> {
    let formData = new FormData();
    formData.append('file',fileInput.value.files[0])
    axios.post('api/upload/image',formData,{
      headers:{
        'content-type':'multipart/form-data'
      }
    }).then((res)=>{
      formSubmitData.value.image = res.data.data;
    })
  }
}
</script>