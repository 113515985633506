

  <template>
    <div class="bg-white m-4 rounded-xl flex flex-col">
      <div class="mt-2 ml-4 p-2 text-xl text-gray-600">
        商品管理
      </div>
      <div class="flex ml-4 p-2">
        <div class="flex items-center  text-gray-500  border px-4 py-1 rounded cursor-pointer hover:bg-gray-100" @click="add">
          <plus-icon class="ico font-bold"></plus-icon>
          <span>添加商品</span>
        </div>
        <div class="hidden flex items-center ml-4 text-gray-500  border px-4 py-1 rounded cursor-pointer hover:bg-red-100 hover:text-red-600">
          <trash-icon class="ico font-bold"></trash-icon>
          <span>删除</span>
        </div>
        <div class="hidden flex items-center ml-4 text-gray-500  border px-4 py-1 rounded cursor-pointer hover:bg-gray-100">
          <arrow-up-on-square-icon class="ico font-bold font-black"></arrow-up-on-square-icon>
          <span>导出</span>
        </div>
      </div>
      <div class="table w-full border-collapse border-gray-100 m-4">
        <table class="w-full">
          <thead>
          <tr class="bg-gray-100 rounded text-gray-400">
            <th class="px-6 py-3 text-left ">ID</th>
            <th class="px-6 py-3 text-left ">图片</th>
            <th class="px-6 py-3 text-left ">名称</th>
            <th class="px-6 py-3 text-left ">价格</th>
            <th class="px-6 py-3 text-left ">规格</th>
            <th class="px-6 py-3 text-left ">分类</th>
            <th class="px-6 py-3 text-left ">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr class="border-t border-gray-100 hover:bg-gray-50 cursor-pointer" v-for="(item,index) in indexList " :key="index">
            <td class="px-4 py-4 text-gray-500">{{ index + 1 }}</td>
            <td class="px-4 py-4 text-gray-500 flex">
              <div v-for="(item,index) in JSON.parse(item.images)" :key="index" class="ml-2 mr-2 rounded">
                <img :src="item"  class="h-10 w-10">
              </div>
            </td>
            <td class="px-4 py-4 text-gray-500 ">
              <div class="flex flex-col">
              <span class="bg-purple-100 text-purple-800  me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300"> {{item.name}}</span>
              <span class="mt-2 font-normal text-gray-400 text-xs me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{{item.desc}}</span>
              </div>
             </td>
            <td class="px-4 py-4 text-gray-500">
              <span class="bg-yellow-100 text-yellow-800 text-sm  me-2 px-2.5 py-1 rounded dark:bg-yellow-900 dark:text-yellow-300">  ¥ {{item.price}}</span>
            </td>
            <td class="px-4 py-4 text-gray-500">
              <div v-for="(item,index) in JSON.parse(item.sku)" :key="index">
                <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{{item.key}} : {{item.price}}</span>
              </div>


            </td>
            <td class="px-4 py-4 text-gray-500">{{item.categoryInfo.name}}</td>
            <td class="px-4 py-4 ml-auto">
              <button class="text-blue-300 hover:text-blue-400  rounded mr-2 bg-blue-50 py-2 px-4" @click="editItem(item)">编辑</button>
              <button class="text-red-300 bg-red-50 py-2 px-4 rounded hover:bg-red-100" @click="isDeleteItem(item.id)">删除</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="fixed inset-0 flex items-start pt-[100px] justify-center bg-black bg-opacity-50" v-if="showForm">
      <div class="bg-white p-4 rounded shadow-lg w-[800px] h-[720px] overflow-y-scroll" @click.stop>
        <h2 class="text-xl mb-4">商品信息</h2>
        <form @submit.prevent="submitForm">
          <div class="mb-4 flex">
            <label for="name" class="block w-12 mr-2 text-sm font-medium text-gray-700  items-start mb-2 py-2">名称:</label>
            <input v-model="formSubmitData.name" type="text" id="name" name="name" placeholder="请输入名称"
                   class="focus:outline-none  border w-7/12 bg-gray-50 px-2 rounded-md shadow-sm py-2">
            <select id="category"
                    v-model="formSubmitData.category_id"
                    class="ml-4  border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/12 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="null">请选择分类</option>
              <option :value="item.id" v-for="(item,index) in cateList" :key="index">
                {{item.name}}
              </option>
            </select>
          </div>
          <div class="mb-4 flex">
            <label for="desc" class="block w-12 mr-2 text-sm font-medium text-gray-700  items-start mb-2 py-2">简介:</label>
            <input v-model="formSubmitData.desc" type="text" id="desc" name="desc" placeholder="请输入简介"
                   class="focus:outline-none  border w-10/12 bg-gray-50 px-2 rounded-md shadow-sm py-2">
          </div>
          <div class="mb-4 flex">
            <label for="image" class="block text-sm font-medium text-gray-700 py-2 w-12 items-start mr-2">图片:</label>
          <div class="flex">
            <div class="m-2 relative" v-for="(item,index) in uploadImages" :key="index">
              <img class="h-[60px] w-[60px] object-center rounded-lg" :src="item" alt="">
              <x-circle-icon class="h-5 cursor-pointer absolute top-[-10px] right-[-10px] text-red-500" @click="delImage(index)"></x-circle-icon>
            </div>

            <div class="h-[60px] cursor-pointer w-[60px] rounded-lg flex justify-center items-center border m-2" v-if="uploadImages.length < 1"
                  @click="uploadFile">
              <input type="file" ref="fileInput" id="fileInput"  style="display: none;" @change="handleFileChange">
              <document-plus-icon class="h-[30px] max-w-full text-gray-200  rounded" >

              </document-plus-icon>
            </div>
          </div>
          </div>

          <div class="mb-4 flex">
            <label for="price" class="block text-sm font-medium text-gray-700 w-12 mr-2 items-start py-2">价格:</label>
            <input v-model="formSubmitData.price" type="text" id="price" name="price" placeholder="请输入价格" class="border w-3/12 focus:outline-none  bg-gray-50 px-2 rounded-md shadow-sm py-2">
          </div>

          <div class="mb-4 flex items-center text-gray-400">
            <label class="block text-sm font-medium text-gray-700 w-12 mr-2 items-start py-2">规格:</label>
            <label class="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" v-model="isMultiSelect"  value="" class="sr-only peer">
              <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 focus:outline-none"></div>
              <span class="ms-3 text-sm  text-gray-400 dark:text-gray-300">多规格</span>
            </label>
          </div>
          <div v-if="isMultiSelect">
            <div class="mb-4 flex" >
              <label for="sort" class="block text-sm font-medium text-gray-700 w-12 mr-2 items-start py-2">规格:</label>
              <input  type="text" v-model="multiSelect.key" name="key" placeholder="规格名称" class="border w-2/8 focus:outline-none  bg-gray-50 px-2 rounded-md shadow-sm py-2 mr-4">
              <input  type="text" v-model="multiSelect.price" name="price" placeholder="价格" class="border w-2/8 focus:outline-none  bg-gray-50 px-2 rounded-md shadow-sm py-2">
              <button class="edit_btn ml-4" @click="addMulti">添加</button>
            </div>

            <div class="mb-4 flex" v-for="(item,index) in multiList" :key="index">
              <label for="sort" class="block text-sm font-medium text-gray-700 w-12 mr-2 items-start py-2"></label>
              <input  type="text" v-model="item.key" name="key" placeholder="规格名称" class="border w-2/8 focus:outline-none  bg-gray-50 px-2 rounded-md shadow-sm py-2 mr-4">
              <input  type="text" v-model="item.price"  name="price" placeholder="价格" class="border w-2/8 focus:outline-none  bg-gray-50 px-2 rounded-md shadow-sm py-2">
              <button class="del_btn ml-4" @click="delMulti(index)">删除</button>
            </div>
          </div>
          <div v-if="editor">
            <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 relative">
              <div class="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
                <div class="flex flex-wrap items-center divide-gray-200 sm:divide-x sm:rtl:divide-x-reverse dark:divide-gray-600">
                  <div class="flex items-center space-x-1 rtl:space-x-reverse sm:pe-4">
                    <button type="button"
                            @click="editor.chain().focus().toggleBold().run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704954019999" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13802" width="200" height="200"><path d="M271.732987 168.739482h240.267013c30.084541 0 53.006096 0 81.555711 8.595583 25.991406 7.776956 45.22914 17.191166 68.662336 34.280004 17.293495 12.688718 31.824123 30.800839 42.875587 51.471171 11.767763 22.102928 17.191166 47.173379 17.191167 77.257919 0 38.577796-12.893375 72.960128-34.280004 94.449086-21.488958 25.786749-51.47117 42.875587-85.853503 55.768961 21.488958 0 38.577796 8.595583 60.066753 17.191167 17.191166 8.595583 32.847407 19.033077 47.173379 34.893974 25.172779 27.935645 25.582093 35.200959 33.768362 55.154991 9.925852 24.354152 9.209553 42.875587 9.209553 64.364545 0 30.084541-4.297792 60.066753-17.191166 81.555711-12.893375 21.488958-30.084541 42.875587-51.47117 60.066753-21.488958 17.191166-47.173379 30.084541-77.257919 38.577796-34.280004 8.595583-64.364545 12.893375-98.644549 12.893375H271.732987V168.739482z m94.346757 283.142601h128.72909c17.191166 0 34.280004 0 51.47117-4.297792 17.191166-4.297792 29.265914-9.41421 38.577796-17.191166 13.20036-11.051464 19.340062-18.112122 25.786749-30.084541 9.618867-17.907465 8.595583-30.084541 8.595583-47.173379 0-25.786749-9.41421-50.652543-25.786749-68.662336-24.865794-27.424003-43.284901-34.791646-81.555711-34.280004H366.079744v201.689218z m0 321.925052h137.324673c12.893375 0 30.084541 0 47.173379-4.297792 17.191166-4.297792 29.163585-7.162986 47.173378-17.191166 19.544719-10.846807 29.879884-19.544719 38.577796-34.280004 7.879285-13.302688 17.191166-34.280004 17.191166-60.066753 0-38.577796-7.469971-68.866993-36.940542-90.969921-29.572899-22.102928-66.001799-33.461377-113.175177-33.461377H366.079744v240.267013z m0 0" p-id="13803"></path></svg>
                      <span class="sr-only">加粗</span>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704942340032" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2745" ><path d="M183.36 192H63.936v640h119.424V555.2h308.48V832h119.424V192H491.84v260.992h-308.48V192z m776.576 0h-89.216l-2.24 2.24c-20.8 21.312-47.04 41.024-78.72 60.096a375.68 375.68 0 0 1-89.216 35.968l-5.632 1.408v116.992l9.536-2.688c53.76-15.168 98.944-37.568 136.064-67.328V832H960V192z" fill="#5A5A5A" p-id="2746"></path></svg>
                      <span class="sr-only">H1</span>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704942387501" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3770" width="200" height="200"><path d="M761.6 256c-53.44 0-96.832 18.432-129.792 55.488-32.192 34.944-48 80.384-48.768 136.512v5.76h90.112l0.192-5.504c1.28-37.952 9.28-66.112 23.104-83.968l0.128-0.192c13.568-18.816 33.984-28.544 62.272-28.544 26.944 0 46.272 6.016 59.008 17.728 12.032 11.648 19.136 29.888 19.136 55.616 0 25.792-10.112 50.56-30.272 73.792-13.12 14.08-34.368 32.32-65.28 54.144-55.936 39.168-93.632 69.76-111.744 91.008-33.28 38.208-49.28 82.88-49.28 134.4V768h347.52v-82.304h-234.048c12.672-21.44 40-46.912 83.648-76.16 47.296-32.256 81.536-58.688 100.928-79.872l0.064-0.064c31.872-35.968 48.704-75.776 48.704-120.064 0-45.44-15.36-82.112-46.4-110.784C849.152 270.08 809.28 256 761.6 256z m-575.36 9.6H95.872V768h90.24V550.72h233.216V768h90.24V265.6h-90.24v204.928H186.24V265.6z" fill="#5A5A5A" p-id="3771"></path></svg>
                      <span class="sr-only">H2</span>
                    </button>
                    <button type="button" @click="editor.chain().focus().toggleCodeBlock().run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704954050631" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14793" width="200" height="200"><path d="M45.76 462.72a729.6 729.6 0 0 1 224-224 96 96 0 1 1 98.88 164.48A496 496 0 0 0 246.08 512a564.8 564.8 0 0 0 123.52 109.12 96 96 0 1 1-98.88 164.48 729.6 729.6 0 0 1-224-224 96 96 0 0 1-0.96-98.88zM978.24 561.28a729.6 729.6 0 0 1-224 224 96 96 0 1 1-98.88-164.48 496 496 0 0 0 122.56-108.8 564.8 564.8 0 0 0-123.52-109.12 96 96 0 1 1 98.88-164.48 729.6 729.6 0 0 1 224 224 96 96 0 0 1 0.96 98.88z" fill="#231F20" p-id="14794"></path></svg>
                      <span class="sr-only">Format code</span>
                    </button>

                    <button type="button" @click="openModal" class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704943370058" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4753"><path d="M768 128H256a128 128 0 0 0-128 128v512a128 128 0 0 0 128 128h512a128 128 0 0 0 128-128V256a128 128 0 0 0-128-128zM256 213.333333h512a42.666667 42.666667 0 0 1 42.666667 42.666667v356.693333l-136.533334-116.48a118.186667 118.186667 0 0 0-150.186666 0L213.333333 755.2V256a42.666667 42.666667 0 0 1 42.666667-42.666667z" p-id="4754"></path><path d="M341.333333 362.666667m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" p-id="4755"></path></svg>
                      <span class="sr-only">远程图片</span>
                    </button>

                    <button type="button" @click="uploadEditorFile" class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704955257044" class="icon" viewBox="0 0 1040 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28788" width="200" height="200"><path d="M986.4068 263.666098l-75.878155 0 0 75.877131c0 20.937868-16.997116 37.936007-37.934984 37.936007-20.945031 0-37.942147-16.999163-37.942147-37.936007l0-75.877131L758.774383 263.666098c-20.942985 0-37.936007-16.999163-37.936007-37.942147 0-20.942985 16.993023-37.934984 37.936007-37.934984l75.877131 0 0-75.878155c0-20.942985 16.997116-37.940101 37.942147-37.940101 20.937868 0 37.934984 16.997116 37.934984 37.940101l0 75.878155 75.878155 0c20.942985 0 37.940101 16.991999 37.940101 37.934984C1024.346901 246.667959 1007.349785 263.666098 986.4068 263.666098L986.4068 263.666098zM758.774383 396.447241c0 31.41243-25.491581 56.909128-56.909128 56.909128-31.413454 0-56.904011-25.497721-56.904011-56.909128s25.490557-56.904011 56.904011-56.904011C733.281779 339.54323 758.774383 365.035834 758.774383 396.447241L758.774383 396.447241zM607.019097 263.666098 75.877131 263.666098l0 528.748453c0.076748-0.265036 83.047438-372.255259 225.244021-372.255259 93.66731 0 188.932 287.689235 248.310366 374.648772 0 0 46.549176-188.480722 126.866433-189.69641 79.44233-1.177825 120.417557 187.301873 120.417557 187.301873l37.936007 0L834.651514 567.175647c0-20.942985 16.997116-37.942147 37.942147-37.942147 20.937868 0 37.934984 16.999163 37.934984 37.942147l0 303.509549c0 41.919738-33.989115 75.877131-75.877131 75.877131L75.877131 946.562327c-41.919738 0-75.877131-33.957393-75.877131-75.877131L0 263.666098c0-41.924855 33.957393-75.877131 75.877131-75.877131l531.141966 0c20.942985 0 37.942147 16.991999 37.942147 37.934984C644.961244 246.667959 627.962082 263.666098 607.019097 263.666098L607.019097 263.666098zM607.019097 263.666098" fill="#272636" p-id="28789"></path></svg>
                      <span class="sr-only">上传图片</span>
                    </button>

                    <button type="button"
                            @click="editor.chain().focus().setTextAlign('left').run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704953806357" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12422" width="200" height="200"><path d="M96 128h832v96H96zM96 576h832v96H96zM96 352h576v96H96zM96 800h576v96H96z" p-id="12423"></path></svg>
                      <span class="sr-only">左对齐</span>
                    </button>
                    <button type="button"
                            @click="editor.chain().focus().setTextAlign('center').run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704953967869" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12561" width="200" height="200"><path d="M96 128h832v96H96zM96 576h832v96H96zM224 352h576v96H224zM224 800h576v96H224z" p-id="12562"></path></svg>
                      <span class="sr-only">居中</span>
                    </button>
                    <button type="button"
                            @click="editor.chain().focus().setTextAlign('right').run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704953986389" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12839" width="200" height="200"><path d="M96 128h832v96H96zM96 576h832v96H96zM352 352h576v96H352zM352 800h576v96H352z" p-id="12840"></path></svg>
                      <span class="sr-only">右对齐</span>
                    </button>


                  </div>
                  <div class="flex flex-wrap items-center space-x-1 rtl:space-x-reverse sm:ps-4">
                    <button type="button"
                            @click="editor.chain().focus().toggleOrderedList().run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704945451764" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9813" width="200" height="200"><path d="M920 760H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM920 192H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM920 476H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM216 712H100c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h72.4v20.5h-35.7c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h35.7V838H100c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h116c2.2 0 4-1.8 4-4V716c0-2.2-1.8-4-4-4zM100 188h38v120c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4V152c0-4.4-3.6-8-8-8h-78c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4zM216 428H100c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4h68.4l-70.3 77.7c-1.3 1.5-2.1 3.4-2.1 5.4V592c0 2.2 1.8 4 4 4h116c2.2 0 4-1.8 4-4v-36c0-2.2-1.8-4-4-4h-68.4l70.3-77.7c1.3-1.5 2.1-3.4 2.1-5.4V432c0-2.2-1.8-4-4-4z" p-id="9814"></path></svg>
                      <span class="sr-only">有序列表</span>
                    </button>
                    <button type="button"
                            @click="editor.chain().focus().toggleBulletList().run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                    <svg t="1704945412828" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9671" width="200" height="200"><path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM912 476H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM912 760H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z" p-id="9672"></path><path d="M160 228m-56 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0Z" p-id="9673"></path><path d="M160 512m-56 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0Z" p-id="9674"></path><path d="M160 796m-56 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0Z" p-id="9675"></path></svg>
                      <span class="sr-only">无序列表</span>
                    </button>
                    <button type="button"
                            @click="editor.chain().focus().undo().run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704944540994" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8461" width="200" height="200"><path d="M894.976 574.464q0 78.848-29.696 148.48t-81.408 123.392-121.856 88.064-151.04 41.472q-5.12 1.024-9.216 1.536t-9.216 0.512l-177.152 0q-17.408 0-34.304-6.144t-30.208-16.896-22.016-25.088-8.704-29.696 8.192-29.696 21.504-24.576 29.696-16.384 33.792-6.144l158.72 1.024q54.272 0 102.4-19.968t83.968-53.76 56.32-79.36 20.48-97.792q0-49.152-18.432-92.16t-50.688-76.8-75.264-54.784-93.184-26.112q-2.048 0-2.56 0.512t-2.56 0.512l-162.816 0 0 80.896q0 17.408-13.824 25.6t-44.544-10.24q-8.192-5.12-26.112-17.92t-41.984-30.208-50.688-36.864l-51.2-38.912q-15.36-12.288-26.624-22.016t-11.264-24.064q0-12.288 12.8-25.6t29.184-26.624q18.432-15.36 44.032-35.84t50.688-39.936 45.056-35.328 28.16-22.016q24.576-17.408 39.936-7.168t16.384 30.72l0 81.92 162.816 0q5.12 0 10.752 1.024t10.752 2.048q79.872 8.192 149.504 41.984t121.344 87.552 80.896 123.392 29.184 147.456z" p-id="8462"></path></svg>
                      <span class="sr-only">撤销</span>
                    </button>

                    <button type="button"
                            @click="editor.chain().focus().redo().run()"
                            class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                      <svg t="1704944556030" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8606" width="200" height="200"><path d="M108.544 572.416q0-77.824 29.184-147.456t80.896-123.392 121.344-87.552 149.504-41.984q10.24-3.072 21.504-3.072l162.816 0 0-81.92q1.024-20.48 16.384-30.72t39.936 7.168q7.168 5.12 27.648 20.992t45.568 35.84 50.688 40.448 44.032 35.84q16.384 13.312 28.672 26.624t12.288 25.6q0 14.336-10.752 24.064t-26.112 22.016l-51.2 38.912q-26.624 19.456-50.688 36.864t-42.496 30.208-26.624 16.896q-15.36 9.216-26.112 11.776t-17.408-0.512-10.24-10.24-3.584-15.36l0-81.92-162.816 0-5.12 0q-50.176 5.12-93.184 26.112t-75.264 54.784-50.688 76.8-18.432 92.16q0 52.224 20.48 97.792t56.32 79.36 83.968 53.76 102.4 19.968l158.72-1.024q16.384 0 33.28 6.144t29.696 16.384 20.992 24.064 8.192 30.208q0 15.36-8.192 29.696t-22.016 24.576-30.72 16.384-33.28 6.144l-177.152 0-9.216 0q-4.096 0-9.216-1.024-80.896-7.168-151.04-41.472t-121.856-88.064-81.408-123.392-29.696-148.48z" p-id="8607"></path></svg>
                      <span class="sr-only">重做</span>
                    </button>
                  </div>
                </div>
                <button type="button" data-tooltip-target="tooltip-fullscreen" class="p-2 text-gray-500 rounded cursor-pointer sm:ms-auto hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 1h5m0 0v5m0-5-5 5M1.979 6V1H7m0 16.042H1.979V12M18 12v5.042h-5M13 12l5 5M2 1l5 5m0 6-5 5"/>
                  </svg>
                  <span class="sr-only">Full screen</span>
                </button>
                <div id="tooltip-fullscreen" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                  Show full screen
                  <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
              </div>
              <div class="px-4 py-2 bg-white rounded-b-lg  dark:bg-gray-800 h-[200px]">
                <editor-content :editor="editor" ></editor-content>
              </div>
            </div>

          </div>

          <div class="text-right">
            <button type="submit" class="px-6 py-2 mr-8 bg-gray-100 text-gray-500 rounded hover:bg-gray-200" @click="hideForm">取消</button>
            <button type="submit" class="px-6 py-2 bg-blue-400 text-white  hover:text-white rounded hover:bg-blue-500" @click="formSave" >提交</button>
          </div>
        </form>
      </div>
    </div>
    <div class="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50" v-if="isShowModal">
      <div class="bg-white p-4 rounded shadow-lg w-[600px]">
        <h2 class="text-xl mb-4">远程图片</h2>
        <div class="mb-4 flex">
          <label for="image" class="block text-sm font-medium text-gray-700 py-2 w-12 items-start mr-2">图片:</label>
          <input  type="text" v-model="imageUrl" id="imageUrl" name="imageUrl" placeholder="请输入图片地址"
                  class="focus:outline-none  border w-[300px] bg-gray-50 px-2 rounded-md shadow-sm py-2">
          <button class="w-[80px] text-blue-300 hover:text-blue-400  rounded mr-2 bg-blue-50 py-2 px-3 ml-2" @click="saveImageUrl">确定</button>
          <button type="submit" class="px-6 py-2 mr-8 bg-gray-100 text-gray-500 rounded hover:bg-gray-200" @click="hideModalForm">取消</button>
        </div>
      </div>
    </div>

    <div class="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50" v-if="showDeleteAlert">
    <div id="alert-additional-content-2" class="p-4 mb-4 w-[300px] text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
      <div class="flex items-center">
        <svg class="flex-shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span class="sr-only">Info</span>
        <h3 class="text-lg font-medium">警告</h3>
      </div>
      <div class="mt-2 mb-4 text-sm">
        确认要删除吗?
      </div>
      <div class="flex">
        <button type="button" @click="deleteItem(item)" class="text-white focus:outline-none bg-red-800 hover:bg-red-900 focus:ring-4  focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
          <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30598" width="200" height="200"><path d="M64 523.136l90.5088-90.5088 235.3152 235.3152L878.592 179.2l90.496 90.5088-488.7424 488.7552-90.5088 90.496L64 523.136z" fill="#ffffff" p-id="30599"></path></svg>
          确定
        </button>
        <button type="button" @click="hideDeleteForm" class="flex focus:outline-none text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-2" aria-label="Close">
          <svg  class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="31649" width="200" height="200"><path d="M842.947458 778.116917 576.847937 512.013303 842.946434 245.883083c8.67559-8.674567 13.447267-20.208251 13.43908-32.477692-0.008186-12.232602-4.7727-23.715121-13.414521-32.332383-8.655124-8.677637-20.149922-13.450337-32.384571-13.4575-12.286838 0-23.808242 4.771677-32.474622 13.434987L512.019443 447.143876 245.88206 181.050496c-8.66331-8.66331-20.175505-13.434987-32.416294-13.434987-12.239765 0-23.75196 4.770653-32.414247 13.43294-8.66024 8.636704-13.428847 20.12434-13.437034 32.356942-0.008186 12.269441 4.76349 23.803125 13.437034 32.476669l266.135336 266.13022L181.050496 778.11794c-8.664334 8.66331-13.43601 20.173458-13.43601 32.41527 0 12.239765 4.7727 23.752983 13.437034 32.417317 8.662287 8.66331 20.173458 13.43294 32.413224 13.43294 12.240789 0 23.754007-4.770653 32.416294-13.43294l266.134313-266.100544 266.101567 266.100544c8.66331 8.66331 20.185738 13.43294 32.4429 13.43294 12.265348-0.008186 23.74889-4.771677 32.369222-13.412474C860.81643 825.081555 860.821547 795.991006 842.947458 778.116917z" fill="#cdcdcd" p-id="31650"></path></svg>
          取消
        </button>
      </div>
    </div>
    </div>


  </template>

  <style scoped>

  </style>
  <script setup>
  import {PlusIcon,TrashIcon,ArrowUpOnSquareIcon,DocumentPlusIcon,XCircleIcon} from "@heroicons/vue/20/solid";
  import {ref} from "vue";
  import axios from "axios";
  const showForm = ref(false);
  const formSubmitData = ref({})
  const showDeleteAlert = ref(false)
  const uploadImages= ref([])
  const indexList = ref();
  const fileInput = ref()
  const multiSelect = ref({}) //当前新增的
  const isMultiSelect = ref(false)//是否为多规格
  const multiList = ref([])//多规格列表
  const isShowModal = ref(false)
  const imageUrl = ref()
  const cateList = ref([])
  import {useEditor,EditorContent} from "@tiptap/vue-3";
  import Document from "@tiptap/extension-document";
  import Paragraph from '@tiptap/extension-paragraph'
  import Text from '@tiptap/extension-text'
  import TextAlign from '@tiptap/extension-text-align'
  import {StarterKit} from "@tiptap/starter-kit";
  import Image from '@tiptap/extension-image'
  const content = ref('')
  const hideDeleteForm = ()=>{
    showDeleteAlert.value = false
  }
  const editor = useEditor({
    content:'',
    isActive:true,
    editorProps:{
      attributes:{
        class:"editor"
      }
    },
    extensions:[
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Image,
      StarterKit,
      Document,
      Text,
      Paragraph
    ],
    autofocus:true,
    editable:true,
    injectCSS:false
  })

  const addMulti = ()=>{
    multiList.value.push({...multiSelect.value})
    multiSelect.value = {}
  }
  function add(){
    axios.get('api/category/index').then(( res )=>{
      cateList.value = res.data.data
    })
    showForm.value = true
  }
  const delImage = (index)=>{
    uploadImages.value.splice(index,1)
  }
  const delMulti = (index)=>{
    multiList.value.splice(index,1)
  }
  function hideForm(){
    showForm.value = false
  }
  async function formSave(){
    formSubmitData.value = {
      ...formSubmitData.value,//保留当前的对象
      content:content.value,
      images:JSON.stringify(uploadImages.value),
      sku:JSON.stringify({...multiList.value})
    }
    try{
      await axios.post('api/product/save',formSubmitData.value);
      formSubmitData.value = ref()
      showForm.value = false
      getList()
    }catch (error){
      console.error('提交失败',error)
    }
  }
  function editItem(item){
    showForm.value = true;
    let deepData = JSON.parse(JSON.stringify(item));
    content.value = deepData.content
    uploadImages.value = JSON.parse(deepData.images)
    multiList.value = JSON.parse(deepData.sku)
   if(multiList.value){
     isMultiSelect.value = true
   }
    formSubmitData.value = JSON.parse(JSON.stringify(item));
  }
  const deleteId = ref('');
  const isDeleteItem = (id)=>{
    showDeleteAlert.value = true
    deleteId.value = id;
  }
  function deleteItem(){
    showDeleteAlert.value = true;
    let id = deleteId.value
    axios.post('api/product/delete',{id}).then(()=>{
      getList();
      formSubmitData.value = ref()
      showDeleteAlert.value = false
    })
  }

  function getList(){
    axios.get('api/product/index').then((res)=>{
      indexList.value = res.data.data
    })
  }
  getList();
  function uploadFile(){
    fileInput.value.click();
    fileInput.value.onchange = ()=> {
      let formData = new FormData();
      formData.append('file',fileInput.value.files[0])
      axios.post('api/upload/image',formData,{
        headers:{
          'content-type':'multipart/form-data'
        }
      }).then((res)=>{
        uploadImages.value.push(res.data.data);
      })
    }
  }

  const openModal = ()=>{
      isShowModal.value = true
  }
  const hideModalForm = ()=>{
    isShowModal.value = false
  }
  const saveImageUrl = ()=>{
    editor.value.chain().focus().setImage({src:imageUrl.value}).run()
    isShowModal.value = false
    imageUrl.value = ''
  }
  const uploadEditorFile = ()=>{
    fileInput.value.click()
    fileInput.value.onchange = ()=> {
      let formData = new FormData();
      formData.append('file', fileInput.value.files[0])
      axios.post('api/upload/image', formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((res) => {
        let imgUrl =  res.data.data;
        editor.value.chain().focus().setImage({ src: imgUrl }).run()
      })
    }
  }
  </script>