

<template>
  <div class="m-8 p-2 text-xl text-gray-600">
    <div>数据概览</div>
  </div>
  <div class="flex m-8 ">
    <div class="p-2 rounded-l-xl bg-gray-100 flex-1 m-2 flex flex-col items-center py-8">
        <div class="text-3xl text-gray-600">28</div>
         <div class="mt-4 text-sm">收益</div>
    </div>
    <div class="p-2 rounded-l-xl bg-gray-100 flex-1 m-2 flex flex-col items-center py-8">
      <div class="text-3xl text-gray-600">28</div>
      <div class="mt-4 text-sm">订单</div>
    </div>
    <div class="p-2 rounded-l-xl bg-gray-100 flex-1 m-2 flex flex-col items-center py-8">
      <div class="text-3xl text-gray-600">28</div>
      <div class="mt-4 text-sm">产品</div>
    </div>
    <div class="p-2 rounded-l-xl bg-gray-100 flex-1 m-2 flex flex-col items-center py-8">
      <div class="text-3xl text-gray-600">28</div>
      <div class="mt-4 text-sm">会员</div>
    </div>
  </div>
  <div class="m-8 p-2 text-xl text-gray-600">
    <div>最新订单</div>
  </div>
</template>

<style scoped>

</style>
<script setup>

</script>