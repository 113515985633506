

<template>
  <div class="mx-auto p-5 bg-white w-[1000px]">
    <el-row :gutter="2" style="display: flex; align-items: center;">
      <el-col :span="6">
        <el-form-item label="标题类型" prop="title_type">
          <el-input v-model="title_type" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="标题" prop="title">
          <el-input v-model="title" disabled placeholder="输入完整标题"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="字数" prop="word_count">
          <el-input v-model="word_count" disabled></el-input>
<!--          <el-input v-model="code" disabled></el-input>-->
        </el-form-item>
      </el-col>
    </el-row>
    <!-- Flowbite Select -->

    <div class="flex w-full h-screen">
      <div class="h-[600px]">
        <div class="ml-4">原始数据</div>
        <form class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
          <div class="px-4 py-2 bg-white rounded-b-lg dark:bg-gray-800">
            <label for="editor" class="sr-only">Publish post</label>
            <el-input type="textarea" v-model="content" :rows="30" style="width: 400px" disabled></el-input>
          </div>
        </form>

        <el-button type="primary" @click="submit" class="ml-4">开始生成正文</el-button>

<!--        <el-button type="primary" @click="to_change" plain>转换</el-button>-->
      </div>
      <div class="h-[600px]">
        <div class="container mx-auto px-4 w-[580px]">
          <el-button type="primary" @click="add_chapter" plain>添加章节</el-button>

          <div v-for="(chapter, chapterIndex) in chapters" :key="chapterIndex">
            <div class="text-gray-500 hover:bg-gray-200  mt-2 bg-gray-100 py-4 px-6 rounded flex">{{ chapter.chapter }}
              <span style="margin-left: auto; " class="flex items-center mr-4 cursor-pointer" @click="handleAddSection(chapterIndex)">
     <svg t="1718162752607" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8265" width="120" height="120"><path d="M892.6527488 566.6111488h-326.24913067v327.68546133c0 30.1989888-24.3564544 54.61224107-54.3752192 54.61224107-29.999104 0-54.3555584-24.41434453-54.3555584-54.61224107V566.6111488h-326.24913066c-30.0089344 0-54.3653888-24.4711424-54.3653888-54.60350293 0-30.16075947 24.3564544-54.63190187 54.3653888-54.63190187h326.24913066V129.70011307c0-30.14219093 24.3564544-54.60350293 54.3555584-54.60350294 30.02750293 0 54.38395733 24.461312 54.38395734 54.60350294V457.375744h326.23930026c30.05590187 0 54.3555584 24.4711424 54.3555584 54.63190187 0.00218453 30.13236053-24.297472 54.60350293-54.35446613 54.60350293z m0 0" fill="#1296db" p-id="8266"></path></svg>
                <b class="text-sm text-blue-400">添加</b>
             </span>

              <span  class="cursor-pointer" @click="editChapter(chapterIndex)"><svg t="1718161542956" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4350" width="12" height="12"><path d="M65.361 856H967v160H65.361zM65.531 805.062l2.295-188.972L676.402 7.515 863.078 194.19 254.503 802.766 65.53 805.062z m50.726-169.52l-1.46 120.254 120.254-1.46L116.257 635.54z m507.147-507.147L742.198 247.19l52.163-52.163L675.567 76.232l-52.163 52.163z" fill="#1AA5FF" p-id="4351"></path></svg></span>
              <span class="ml-2 cursor-pointer" @click="deleteChapter(chapterIndex)"><svg t="1718161573745" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5738" width="12" height="12"><path d="M0 136.594286m70.034286 0l883.931428 0q70.034286 0 70.034286 70.034285l0 61.074286q0 70.034286-70.034286 70.034286l-883.931428 0q-70.034286 0-70.034286-70.034286l0-61.074286q0-70.034286 70.034286-70.034285Z" fill="#d81e06" p-id="5739"></path><path d="M685.714286 146.285714h-73.142857V73.142857h-201.142858v73.142857h-73.142857V73.142857a73.142857 73.142857 0 0 1 73.142857-73.142857h201.142858a73.142857 73.142857 0 0 1 73.142857 73.142857zM164.571429 274.285714v676.571429a73.142857 73.142857 0 0 0 73.142857 73.142857h548.571428a73.142857 73.142857 0 0 0 73.142857-73.142857V274.285714z m233.142857 521.142857a36.571429 36.571429 0 0 1-73.142857 0v-329.142857a36.571429 36.571429 0 0 1 73.142857 0z m155.428571 0a36.571429 36.571429 0 0 1-73.142857 0v-329.142857a36.571429 36.571429 0 0 1 73.142857 0z m146.285714 0a36.571429 36.571429 0 0 1-73.142857 0v-329.142857a36.571429 36.571429 0 0 1 73.142857 0z" fill="#d81e06" p-id="5740"></path></svg></span>
            </div>
            <div v-for="(section, sectionIndex) in chapter.sections" :key="sectionIndex">
              <div class="text-gray-600 hover:bg-gray-100  mt-1 rounded py-2 px-8 bg-gray-50 flex items-center" >{{ section.section }}
                <span style="margin-left: auto" class="cursor-pointer"  @click="editSection(chapterIndex, sectionIndex)"><svg t="1718161542956" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4350" width="12" height="12"><path d="M65.361 856H967v160H65.361zM65.531 805.062l2.295-188.972L676.402 7.515 863.078 194.19 254.503 802.766 65.53 805.062z m50.726-169.52l-1.46 120.254 120.254-1.46L116.257 635.54z m507.147-507.147L742.198 247.19l52.163-52.163L675.567 76.232l-52.163 52.163z" fill="#1AA5FF" p-id="4351"></path></svg></span>

                <span class="ml-2 cursor-pointer" @click="deleteSection(chapterIndex, sectionIndex)"><svg t="1718161573745" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5738" width="12" height="12"><path d="M0 136.594286m70.034286 0l883.931428 0q70.034286 0 70.034286 70.034285l0 61.074286q0 70.034286-70.034286 70.034286l-883.931428 0q-70.034286 0-70.034286-70.034286l0-61.074286q0-70.034286 70.034286-70.034285Z" fill="#d81e06" p-id="5739"></path><path d="M685.714286 146.285714h-73.142857V73.142857h-201.142858v73.142857h-73.142857V73.142857a73.142857 73.142857 0 0 1 73.142857-73.142857h201.142858a73.142857 73.142857 0 0 1 73.142857 73.142857zM164.571429 274.285714v676.571429a73.142857 73.142857 0 0 0 73.142857 73.142857h548.571428a73.142857 73.142857 0 0 0 73.142857-73.142857V274.285714z m233.142857 521.142857a36.571429 36.571429 0 0 1-73.142857 0v-329.142857a36.571429 36.571429 0 0 1 73.142857 0z m155.428571 0a36.571429 36.571429 0 0 1-73.142857 0v-329.142857a36.571429 36.571429 0 0 1 73.142857 0z m146.285714 0a36.571429 36.571429 0 0 1-73.142857 0v-329.142857a36.571429 36.571429 0 0 1 73.142857 0z" fill="#d81e06" p-id="5740"></path></svg></span>

              </div>

            </div>
          </div>
        </div>
        <div class="m-8 hidden">
          <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">字数</dt>
                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{word_count}}字</dd>
              </div>

              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">状态</dt>
                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ status }}</dd>
              </div>
              <div v-if="show_result">
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">附件</dt>
                  <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                      <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div class="flex w-0 flex-1 items-center">
                          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd" />
                          </svg>

                        </div>
                        <div class="ml-4 flex-shrink-0">
                          <a :href="file_url" class="font-medium text-indigo-600 hover:text-indigo-500">开始下载</a>
                        </div>
                      </li>
                    </ul>
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<style scoped>
.el-button--primary >>> .el-button__inner {
  background-color: #545659 !important;
  color:red;
}
</style>
<script setup>
import {ref } from "vue";
import axios from "axios";
import {ElMessageBox, ElMessage,ElRow, ElCol, ElFormItem, ElInput, ElLoading} from 'element-plus';
const content = ref("")
const word_count = ref("")
const title_type = ref("")
const title = ref("")
const tmp_json = ref("")
const chapters = ref('')
const code = ref('')

// const updateProgress = (totalChapters) => {
//   // 每次生成完一个章节后，更新进度条
//    progress.value = Math.round((progress.value * totalChapters + 100) / (totalChapters + 1));
// };
const fetchData = async (id,post_id) => {
  try {
    const response = await axios.get(`/api/get_data/${id}/${post_id}`);
    content.value = response.data.ai_message.content;
    word_count.value = response.data.post.wordCount
    code.value = response.data.post.code
    title.value = response.data.post.titleText
    title_type.value = response.data.post.titleType
    chapters.value = parseMarkdownToJSON(content.value).chapters
    console.log(tmp_json.value); // 解析函数已返回对象，不用再次调用 JSON.parse
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


const deleteChapter = (chapterIndex) => {
  ElMessageBox.confirm('确认删除该章节?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    chapters.value.splice(chapterIndex, 1);
    ElMessage({
      type: 'success',
      message: '删除成功',
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
};

const editSection = (chapterIndex, sectionIndex) => {
  ElMessageBox.prompt('编辑section', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputValue: chapters.value[chapterIndex].sections[sectionIndex].section
  }).then(({ value }) => {
    console.log(value)
    // 获取当前章节的 Section 数量
    // const sectionCount = chapters.value[chapterIndex].sections.length;
    // 保持序号不变
  //  const updatedSection = value.split('. ').slice(-1)[0];
   chapters.value[chapterIndex].sections[sectionIndex].section = value;
    ElMessage({
      type: 'success',
      message: '编辑成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消'
    });
  });
};


const editChapter = (chapterIndex) => {
  // TODO: 实现编辑章节功能
  ElMessageBox.prompt('编辑章节', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputValue: chapters.value[chapterIndex].chapter
  }).then(({ value }) => {
    chapters.value[chapterIndex].chapter = value;
    ElMessage({
      type: 'success',
      message: '编辑成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消编辑'
    });
  });
};

const add_chapter = () => {
  ElMessageBox.prompt('新增章节', {
    confirmButtonText: '确定',
    cancelButtonText: '取消'
  }).then(({ value }) => {
    // 1. 获取当前章节数据
    const currentChapters = chapters.value;

    // 2. 创建新的章节数据
    const newChapter = {
      chapter: `第${currentChapters.length+1}章 :`+value, // 使用用户输入的名称
      sections: []
    };

    // 3. 将新章节数据添加到 post_data.chapters 中
    currentChapters.push(newChapter);

    // 4. 更新章节生成状态
    this.chapter_status[currentChapters.length - 1] = false;

    // 5. 更新 UI
    // ... 根据你的需求，更新 UI
    // 例如：使用 v-for 重新渲染章节列表

    ElMessage({
      type: 'success',
      message: '新增成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消新增'
    });
  });
};

const handleAddSection = (chapterIndex) => {
  ElMessageBox.prompt('新增section', {
    confirmButtonText: '确定',
    cancelButtonText: '取消'
  }).then(({ value }) => {
    // 获取当前章节的 Section 数量
    const sectionCount = chapters.value[chapterIndex].sections.length;

    // 将序号添加到标题
    const newSection = {
      section: `${chapterIndex+1}.${sectionCount + 1} ${value}` // 添加序号
    };

    chapters.value[chapterIndex].sections.push(newSection);
    ElMessage({
      type: 'success',
      message: '新增成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消新增'
    });
  });
};

const deleteSection = (chapterIndex, sectionIndex) => {
  ElMessageBox.confirm('确认删除section?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    // chapters.value.splice(chapterIndex, 1);
    chapters.value[chapterIndex].sections.splice(sectionIndex, 1);
    ElMessage({
      type: 'success',
      message: '删除成功',
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
};
function parseMarkdownToJSON(markdown) {
  const lines = markdown.split('\n');
  const result = { chapters: [] };
  let currentChapter = null;
  let currentSection = null;

  lines.forEach(line => {
    if (line.startsWith('# ')) {
      if (currentChapter) {
        if (currentSection) {
          currentChapter.sections.push(currentSection);
          currentSection = null;
        }
        result.chapters.push(currentChapter);
      }
      currentChapter = { chapter: line.substring(2), sections: [] };
    } else if (line.startsWith('## ')) {
      if (currentSection) {
        currentChapter.sections.push(currentSection);
      }
      currentSection = { section: line.substring(3) };
    }
  });

  if (currentSection) {
    currentChapter.sections.push(currentSection);
  }
  if (currentChapter) {
    result.chapters.push(currentChapter);
  }

  return result;
}
// const to_change = ()=>{
//   let res = parseMarkdownToJSON(content.value)
//   chapters.value = res.chapters
// }
const status = ref('待生成')
const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
const id = urlParams.get('id');
const post_id = urlParams.get('post_id');
fetchData(id,post_id);
const show_result = ref(false)
const file_url = ref('')
// const submit = async ()=>{
//
//
//   progress.value = 20;
//   console.log(json)
//   axios.post('http://127.0.0.1:5000/generate-chapter', json).then((res) => {
//     console.log(res)
//   });
//
// }
const submit = async () => {
  const loadingInstance = ElLoading.service({
    lock: true,
    text: '正在生成请不要关闭页面',
    background: 'rgba(182,178,178,0.7)',
  });
  try {
    let json = {
      id:id,
      post_id:post_id,
      title:title.value,
      code:code.value,
      title_type:title_type.value,
      content:content.value,
      word_count:word_count.value,
      chapters:JSON.parse(JSON.stringify(chapters.value))
    }
    console.log(json);

    const res = await axios.post('/generate-chapter', json);
    if(res.data.code == 0){
      location.href = `/#/ai/result?code=${code.value}&id=${id}&post_id=${post_id}`;
    }
    // 在组件卸载时清除定时器
  } catch (error) {
    ElMessage({
      message: '请求失败',
      type: 'error'
    });
  } finally {
    loadingInstance.close();
  }
};
</script>