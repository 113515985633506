<template>
  <div class="max-w-4xl mx-auto p-5 bg-white  rounded-lg">
    <div>
      <el-form :inline="true"  class="demo-form-inline">
        <el-form-item label="兑换码">
          <el-input placeholder="请输入兑换码" v-model="my_code"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="check_code" >查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-descriptions title="订单信息" v-if="show_order">
      <el-descriptions-item label="订单编码">{{ code }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{created_at}}</el-descriptions-item>
      <el-descriptions-item label="字数">{{ wordCount }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag size="small" >{{status_msg}}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="标题">{{ title }}</el-descriptions-item>
      <el-descriptions-item label="下载链接" v-if="file_url"><el-button type="primary" size="small" @click="down_file()">下载</el-button></el-descriptions-item>

    </el-descriptions>
<!--    <div class="font-bold mb-4 text-gray-500">{{status}}</div>-->
    <div  class="space-y-4">
<!--      <div>-->
<!--        <h2 class="text-lg font-semibold">订单编码：</h2>-->
<!--        <p class="text-gray-600">下单时间：  </p>-->
<!--        <p class="text-gray-600">论文标题：<span class="font-semibold"></span></p>-->
<!--        <p class="text-gray-600">论文字数：<span class="font-semibold"></span></p>-->
<!--      </div>-->

<!--      <div v-if="order.status === '已生成'">-->
<!--        <h3 class="text-lg font-semibold">下载论文：</h3>-->
<!--        <p class="text-blue-500 hover:text-blue-700">-->
<!--          <a :href="order.downloadPdf" download>下载 PDF 格式</a>-->
<!--        </p>-->
<!--        <p class="text-blue-500 hover:text-blue-700">-->
<!--          <a :href="order.downloadDocx" download>下载 DOCX 格式</a>-->
<!--        </p>-->
<!--      </div>-->
      <div >

      </div>
    </div>
<!--    <el-button type="primary" @click="submit" class="mt-2" v-if="show_btn">开始生成</el-button>-->
<!--    <div  class="text-center text-gray-500">-->
<!--      订单加载中...-->
<!--    </div>-->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from "axios";

const order = ref(null);
const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
const id = urlParams.get('id');
const post_id = urlParams.get('post_id');
const wordCount = ref("")
const title_type = ref("")
const created_at = ref("")
const title = ref("")
const status = ref('待生成')
const status_msg = ref('待生成')
const file_url = ref('')
const code = ref('')
const show_btn = ref(true)
const my_code = ref('')
const show_order = ref(false)
onMounted(async () => {
  if(!post_id){
    show_order.value = false
  }else{
    order.value = await fetchData(id,post_id)
    show_order.value = true
    console.log('timeout')
    let code_str = code.value
    setTimeout(() => {
      generateThesis(code_str);
    }, 1000);
  }

});
const fetchData = async (id,post_id) => {
  try {
    const response = await axios.get(`/api/get_code/${post_id}`);
    console.log(response)
    wordCount.value = response.data.wordCount
    code.value = response.data.code
    title.value = response.data.titleText
    created_at.value = response.data.created_at
    title_type.value = response.data.titleType
    axios.get(`/get_code/${code.value}`).then((res)=> {
      console.log(res.data.code)
      if (res.data.code == 0) {
        code.value = res.data.result.code
        status.value = '生成完成'
        wordCount.value = res.data.post.wordCount
        show_btn.value = false
        show_order.value = true
        title.value = res.data.post.titleText
        file_url.value = res.data.result.file_url
        status_msg.value = '生成完成'
      } else {
        code.value = my_code.value
        status.value = res.data.msg
        wordCount.value = 0
        show_btn.value = false
        show_order.value = true
        title.value = '没有内容'
        // file_url.value = res.data.result.file_url
        status_msg.value = '正在生成'
      }
    })
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


// const submit =  ()=>{
//   show_btn.value = false
//
// }
const check_code = ()=>{
  axios.get(`/get_code/${my_code.value}`).then((res)=> {
    console.log(res.data.code)
    if (res.data.code == 0) {
      code.value = res.data.result.code
      status.value = '生成完成'
      wordCount.value = res.data.post.wordCount
      show_btn.value = false
      show_order.value = true
      title.value = res.data.post.titleText
      file_url.value = res.data.result.file_url
      status_msg.value = '生成完成'
    } else {
      code.value = my_code.value
      status.value = res.data.msg
      wordCount.value = 0
      show_btn.value = false
      show_order.value = true
      title.value = '没有内容'
      // file_url.value = res.data.result.file_url
      status_msg.value = '没有生成'
    }
  })
}
async function generateThesis(code) {
  try {
    console.log(code)
    console.log('get doc')
    // 生成章节
    axios.get(`/get_doc/${code}`)
        .then(response => {
          console.log(code)
          status.value = response.data.data;
          if (response.data.code !== 0) throw new Error(response.data.msg);
          return axios.get(`/make_cover/${code}`);
        })
        .then(response => {
          status.value = response.data.data;
          if (response.data.code !== 0) throw new Error(response.data.msg);
          return axios.get(`/make_abstract_by_ai/${code}`);
        })
        .then(response => {
          status.value = response.data.data;
          if (response.data.code !== 0) throw new Error(response.data.msg);
          return axios.get(`/make_abstract_to_word/${code}`);
        })
        .then(response => {
          status.value = response.data.data;
          if (response.data.code !== 0) throw new Error(response.data.msg);
          return axios.get(`/make_thank/${code}`);
        })
        .then(response => {
          status.value = response.data.data;
          if (response.data.code !== 0) throw new Error(response.data.msg);
          return axios.get(`/make_wenxian/${code}`);
        })
        .then(response => {
          status.value = response.data.data;
          if (response.data.code !== 0) throw new Error(response.data.msg);
          return axios.get(`/compile_doc/${code}`);
        })
        .then(response => {
          status.value = response.data.data;
          if (response.data.code !== 0) throw new Error(response.data.msg);
          console.log("Thesis generation complete!");
        })
        .catch(error => {
          console.error("Error during thesis generation:", error);
        });

  } catch (error) {
    console.error("Error during thesis generation:", error);
  }
}
function to_url(code){
  console.log(code)
  // axios.get(`/download/${code}`).then((res)=>{
  //   console.log(res)
  // })
  window.open(axios.defaults.baseURL+`/download/${code}`)
}
const down_file = ()=>{
  to_url(code.value)
}
// 开始执行

</script>

<style scoped>

</style>