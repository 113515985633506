

<template>
<div class="flex  justify-center items-center  rounded">
  <div class="w-2/4 rounded-l">
    <img src="../assets/code.png"  class="object-center rounded-l">
  </div>
  <div class="p-4 w-3/6">
    <div class="text-2xl flex justify-center mb-8">PayPal 管理系统</div>

    <div class="flex items-center w-6/8 justify-between px-12 py-1 bg-gray-100 rounded-2xl">
      <span class="text-gray-400 w-12">用户名</span>
      <input type="text" v-model="username" class="ml-4 rounded text-gray-500 flex-grow h-8 bg-gray-100 text-xs focus:outline-none" placeholder="请输入用户名">
    </div>

    <div class="flex items-center w-6/8 justify-between px-12 py-1 bg-gray-100 rounded-2xl  mt-8">
      <span class="text-gray-400 w-12">密 码</span>
      <input type="password" v-model="password" class="ml-4 rounded text-gray-500 flex-grow h-8  bg-gray-100 text-xs focus:outline-none" placeholder="请输入密码">
    </div>
    <div class="w-full flex justify-center">
      <button class="px-4 py-2 mt-8 bg-blue-400 text-white w-48 justify-center rounded shadow-xl mb-4" @click="login">登录</button>
    </div>
  </div>
</div>
</template>

<style scoped>

</style>
<script setup>
import  {ref} from "vue";
import {useRouter} from "vue-router";

const username = ref('')
const password = ref('')
const router = useRouter()
function login(){
  localStorage.setItem('token','msmax')
  router.push('/home')
}
</script>