import {createRouter,createWebHashHistory} from "vue-router"

import MainLayout from "@/components/layout/MainLayout.vue";
import LoginLayout from "@/components/layout/LoginLayout.vue";
import AiLayout from "@/components/layout/AiLayout.vue";
import HomePage from "@/views/HomePage.vue";
import CatePage from "@/views/CatePage.vue";
import ProductPage from "@/views/ProductPage.vue";
import OrderPage from "@/views/OrderPage.vue";
import AcountPage from "@/views/AcountPage.vue";
import LoginPage from "@/views/LoginPage.vue";
// import IndexPage from "@/views/IndexPage.vue";
import ListPage from "@/views/ListPage.vue";
import DetailPage from "@/views/DetailPage.vue";
// import DemoLayout from "@/components/layout/DemoLayout.vue";
import AiPage from "@/views/AiPage.vue";
import GeneratePage from "@/views/GeneratePage.vue";
import EditorPage from "@/views/EditorPage.vue";
import ResultPage from "@/views/ResultPage.vue";
import TestPage from "@/views/TestPage.vue";
const routes = [
    {
        path:'/admin',
        component: MainLayout,
        meta:{ requiresAuth:true },
        children:[
            {
                path:'',
                name:'Home',
                component:HomePage
            },
            {
                path:'cate',
                name:"Cate",
                component: CatePage
            },
            {
                path:'product',
                name:"product",
                component: ProductPage
            },
            {
                path:'order',
                name:'Order',
                component: OrderPage
            },
            {
                path:'account',
                name: 'account',
                component: AcountPage
            },
        ]
    },
    {
        path:'/login',
        component: LoginLayout,
        children: [
            {
                path:'',
                name: 'Login',
                component: LoginPage
            }
        ]
    },
    {
        path: '/ai',
        component: AiLayout,
        children: [
            {
                path: '',
                name: 'ai',
                component: AiPage
            },
            {
                path: 'result',
                name: 'result',
                component: ResultPage
            },
            {
                path: "test",
                name: "test",
                component: TestPage
            }
        ]
    },
    {
        path: '/generate',
        name: 'generate',
        component: GeneratePage
    },
    {
        path: '/editor',
        name: 'editor',
        component: EditorPage
    },
    {
        path:'',
        name:'Ai学霸',
        component: AiLayout,
        children: [
            {
                path: '',
                name:'index',
                component: AiPage
            },
            {
                path: 'list',
                name: 'list',
                component: ListPage
            },
            {
                path: 'detail',
                name: 'detail',
                component: DetailPage
            }
        ]
    }
]

const router = createRouter({
    history:createWebHashHistory(process.env.BAsSE_URL),
    routes
})

router.beforeEach((to,from,next) => {
    if(to.matched.some( record => record.meta.requiresAuth)){
        if(!userIsLogin()){
            next({
                name:"Login"
            })
        }else{
            next(); //已登录
        }
        next({
            name:'Login'
        })
    }else{
        next(); //已登录
    }
})
function userIsLogin(){
   let token = localStorage.getItem('token')
    if(token=='msmax'){
        return true;
    }
    return false;
}
export default router;