<template>
  <div class="w-full h-screen bg-red-300">
 <h1>hello editor</h1>
  </div>
</template>

<script setup>

</script>

<style scoped>
</style>