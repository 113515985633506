
<template>
  <div class="container mx-auto">
    <router-view></router-view>
  </div>

</template>

<script setup>
</script>

<style>

</style>