import { createApp } from 'vue'
import App from './App.vue'
import router from  './router/router'
import axios from "axios";
import ElementPlus from 'element-plus';

import "element-plus/dist/index.css"
// axios.defaults.baseURL = 'https://api.dweixin.cn/'
// axios.defaults.baseURL = 'http://209.146.112.113:5000';
// axios.defaults.baseURL = 'http://flask.iaxe.cn';
axios.defaults.baseURL = 'http://127.0.0.1:5000';
import('@/style/app.css')
const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.mount('#app')
// createApp(App).mount('#app')