<script setup>

</script>

<template>
  <div class="text-sm text-gray-400">眉山大旺科技有限公司 版权所有 2024</div>
</template>

<style scoped>

</style>