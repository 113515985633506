<template>
  <div class="mx-auto p-5">
    <h1 class="mb-4 text-xl font-extrabold text-gray-900 dark:text-white border-l-8 px-4 border-gray-300">提交标题</h1>

    <el-form :model="formData" label-width="120px" class="demo-form-inline">
      <el-form-item label="标题类型">
        <el-select v-model="formData.titleType" placeholder="选择标题类型">
          <el-option
              v-for="item in titleTypes"
              :key="item.id"
              :label="item.name"
              :value="item.name"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="标题文本">
        <el-input
            v-model="formData.titleText"
            placeholder="输入完整标题"
            maxlength="50"
        >
          <template #append>
            <span class="text-sm text-gray-600" id="char-count">{{ formData.titleText.length }}/50</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="学历">
        <el-radio-group v-model="formData.education">
          <el-radio :label="education" v-for="education in educations" :key="education">
            {{ education }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="图表公式">
        <el-checkbox-group v-model="formData.chartTypes">
          <el-checkbox :label="type" v-for="type in chartTypes" :key="type">
            {{ type }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="论文专业">
        <el-select v-model="formData.major" placeholder="选择专业">
          <el-option
              v-for="major in majors"
              :key="major.id"
              :label="major.name"
              :value="major.name"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="关键词">
        <el-input
            v-model="inputValue"
            placeholder="输入关键字并按回车键"
            @keyup.enter="handleInput"
            @keyup.space="handleInput"
            @blur="handleInput"
        ></el-input>
        <div style="margin-top: 10px;">
          <el-tag
              v-for="(keyword, index) in keywords"
              :key="index"
              closable
              @close="removeKeyword(index)"
              style="margin-right: 8px;"
          >
            {{ keyword }}
          </el-tag>
        </div>
      </el-form-item>

      <el-form-item label="兑换码">
        <el-input v-model="formData.code" placeholder="请输入兑换码"></el-input>
      </el-form-item>

      <el-form-item label="参考文献">
        <el-switch
            v-model="formData.references"
            active-text="含英文参考文献"
        ></el-switch>
      </el-form-item>

      <el-form-item label="补充说明">
<!--        <el-input v-model="formData.additionalNotes" placeholder="其它相关说明"></el-input>-->
        <el-input type="textarea" v-model="formData.additionalNotes" :rows="4"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-checkbox-group v-model="formData.chartTypes">
          <el-checkbox checked>
            我已阅读并同意生成的论文范文仅用于参考。
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">立即生成大纲</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<style scoped>
.el-textarea__inner {
  height: 100px; /* 设置高度为 100px */
  border:1px solid #ccc;
}
</style>
<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { ElMessage, ElLoading, ElForm, ElFormItem, ElSelect, ElOption, ElRadioGroup, ElRadio, ElCheckboxGroup, ElCheckbox, ElInput, ElTag } from 'element-plus';

const majors = [
  { id: 1, name: '计算机' },
  { id: 2, name: '农业' },
  { id: 3, name: '机械' },
  { id: 4, name: '师范教育' },
  { id: 5 ,name:'经济'},
  { id: 6 ,name:'教育'},
  { id: 7 ,name:'文学'},
  { id: 8 ,name:'医药'},
  { id: 9 ,name:'法律'},
  { id: 10 ,name:'工业技术'},
  { id: 11 ,name:'建筑科学'},
  { id: 12 ,name:'其它（自动识别）'},
];

const titleTypes = [
  { id: 1, name: '毕业论文' },
  { id: 2, name: '开题报告' },
  { id: 3, name: '学术报告' },
];

const educations = ['大专', '本科', '硕博'];
const chartTypes = ['图片', '表格', '公式', '代码'];


const formData = ref({
  titleType: '',
  titleText: '',
  education: '',
  chartTypes: [],
  major: '',
  keywords: [],
  code: '',
  references: '',
  additionalNotes: ''
});

const inputValue = ref('');
const keywords = ref([]);

const handleInput = () => {
  const trimmedValue = inputValue.value.trim();
  if (trimmedValue && !keywords.value.includes(trimmedValue)) {
    keywords.value.push(trimmedValue);
  }
  inputValue.value = '';
};

const removeKeyword = (index) => {
  keywords.value.splice(index, 1);
};

const submitForm = async () => {
  const loadingInstance = ElLoading.service({
    lock: true,
    text: '正在生成提纲',
    background: 'rgba(182,178,178,0.7)',
  });

  let post_data = formData.value;
  formData.value.keywords = keywords.value;
  try {
    const res = await axios.post('/make_catelog', post_data);
    if (res.data.code === 1) {
      ElMessage({
        message: '兑换码错误',
        type: 'warning'
      });
    } else {
      let ai_id = res.data.data.id;
      let post_id = res.data.data.post_id;
      location.href = `/#/generate?id=${ai_id}&post_id=${post_id}`;
    }
  } catch (error) {
    ElMessage({
      message: '请求失败',
      type: 'error'
    });
  } finally {
    loadingInstance.close();
  }
};
</script>