

<template>
  <div class="text-gray-500 flex justify-between">
    <div class="flex items-center">
      <svg t="1713163255570" class="logo" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4367" width="120" height="120"><path d="M449.28 241.002667H738.133333c155.093333 0 213.461333 78.506667 204.458667 194.005333-14.869333 190.442667-130.005333 295.744-282.730667 295.744h-77.098666c-20.906667 0-35.008 13.824-40.704 51.413333l-33.066667 218.325334c-2.154667 14.165333-9.6 22.506667-20.821333 23.509333h-181.12c-17.045333 0-23.104-13.034667-18.645334-41.28l110.613334-700.288c4.416-28.074667 19.776-41.429333 50.282666-41.429333z" fill="#009DE2" p-id="4368"></path><path d="M268.714667 0H557.866667c81.450667 0 178.026667 2.645333 242.645333 59.626667 43.157333 38.058667 65.792 98.709333 60.586667 163.84C843.306667 444.202667 711.253333 567.893333 534.186667 567.893333h-142.613334c-24.277333 0-40.362667 16.064-47.210666 59.626667l-39.786667 253.141333c-2.56 16.426667-9.706667 26.112-22.698667 27.264H103.765333c-19.754667 0-26.773333-15.104-21.610666-47.850666L210.410667 48.042667C215.530667 15.488 233.344 0 268.714667 0z" fill="#113984" p-id="4369"></path><path d="M348.522667 602.005333l50.496-319.573333c4.416-28.074667 19.776-41.429333 50.282666-41.429333H738.133333c47.808 0 86.442667 7.466667 116.714667 21.226666-29.013333 196.48-156.096 305.664-322.538667 305.664H389.76c-18.773333 0-32.789333 9.6-41.216 34.112z" fill="#172C70" p-id="4370"></path></svg>
      <p class="px-2 font-bold">PayPal</p>
    </div>
    <div class="flex items-center relative group">
      <user-icon class="ico"></user-icon>
      <button class="focus:outline-none">管理员</button>
      <chevron-down-icon class="ico px-2"></chevron-down-icon>

      <div class="absolute right-0 top-2 mt-4 py-2 px-2 ml-2 mr-2 w-24 bg-white rounded-lg shadow-xl hidden group-hover:block">
        <a href="#" class="block px-2 py-2 text-xs text-gray-800 hover:bg-gray-100">修改资料</a>
        <a href="#" class="block px-2 py-2 text-xs text-gray-800 hover:bg-gray-100" @click="logout">退出</a>
      </div>
    </div>
  </div>
</template>


<style scoped>

</style>

<script setup>
import {UserIcon,ChevronDownIcon} from "@heroicons/vue/20/solid";
import {useRouter} from "vue-router";
const router = useRouter()
function logout(){
  localStorage.removeItem('token')
  router.push('/login')
}
</script>