<script setup>

</script>

<template>
  <div class="w-full bg-gray-50 h-screen">
    <div class="w-3/5 mx-auto p-8">
      <router-view></router-view>
    </div>

  </div>
</template>

<style scoped>

</style>